import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import React from 'react';
import { useRouteError } from 'react-router-dom';
import i18n from './i18n';

export default () => {
    const error = useRouteError();

    return (
        <View style={styles.container}>
            <Text>{error.message}</Text>
            {error.response && error.response.data && (
                <>
                    <Text>{error.response.data.errorCode}</Text>
                    <Text>{error.response.data.errorMsg}</Text>
                </>
            )}
            <TouchableOpacity
                style={styles.button} 
                onPress={() => {
                    window.location.reload();
                }}
            >
                <Text style={styles.buttonTitle}>
                    {i18n.t('ERROR_RELOAD')}
                </Text>
            </TouchableOpacity>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        margin: 20,
    },
    button: {
        marginVertical: 10,
    },
})