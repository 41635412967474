import { HStack, VStack, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import HtmlText from './HtmlText';

const Column = ({ title = '', text = '', element }) => {
    return (
        <VStack flex={1}>
            <Text bold>{title}</Text>
            <Text>{text || '-'}</Text>
            {element}
        </VStack>
    );
};

const Row = ({ titleList = [], textList = [], elementList = [] }) => {
    return (
        <HStack space={'5'}>
            {titleList.map((title, i) => title ? (
                <Column
                    flex={1}
                    key={i}
                    title={title}
                    text={textList[i]}
                    element={elementList[i]}
                />
            ) : null)}
        </HStack>
    );
};

const OrderDetailContactView = ({ order = {} }) => {
    const {
        orderNo,
        orderAt,
        paymentStatus,
        shippingStatus,
        paymentMethod,
        paymentDescription,
        buyerName,
        buyerPhone,
        buyerEmail,
        recipientName,
        recipientPhone,
        recipientEmail,
        shippingCountry,
        shippingRegion,
        shippingAddress1,
        shippingAddress2,
        shippingTrackCode,
        shippingMethod,
        shippingMethodOption,
        shippingMethodType,
        expectDeliveryDate,
        expectDeliveryPeriod,
        shippingType,
    } = order;

    const hideShippingStatus = shippingType === 'ONLINE';

    const shippingAddress = shippingMethodType === 'ADDRESS' ? [shippingCountry, shippingRegion, shippingAddress1, shippingAddress2].filter(i => i).join(', ') : '';
    let recipentAddress = [shippingMethod, shippingAddress || shippingMethodOption].filter(i => i).join(' - ');
    
    if (shippingTrackCode) {
        recipentAddress += '\n' + t('Track code') + shippingTrackCode;
    }

    const { t } = useTranslation();

    const isRecipientDifferent = buyerName !== recipientName || buyerEmail !== recipientEmail || buyerPhone !== recipientPhone;

    return (
        <VStack space={'5'}>
            <Row
                titleList={[t('Order date')]}
                textList={[orderAt]}
            />
            <Row
                titleList={[t('Payment status'), t('Payment method')]}
                textList={[paymentStatus, paymentMethod]}
                elementList={[undefined, <HtmlText content={paymentDescription}/>]}
            />
            {!hideShippingStatus && (
                <Row
                    titleList={[t('Delivery status'), t('Delivery address')]}
                    textList={[shippingStatus, recipentAddress]}
                />
            )}
            <Row
                titleList={[t('Customer name'), t('Customer phone')]}
                textList={[buyerName, buyerPhone]}
            />
            <Row
                titleList={[t('Customer email')]}
                textList={[buyerEmail]}
            />
            {isRecipientDifferent && (
                <>
                    <Row
                        titleList={[t('Recipient phone'), t('Recipient phone')]}
                        textList={[recipientName, recipientPhone]}
                    />
                    <Row
                        titleList={[t('Recipient email')]}
                        textList={[recipientEmail]}
                    />
                </>
            )}
            {expectDeliveryDate && (
                <Row
                    titleList={[
                        t('FORM.delivery_date'),
                        t('FORM.delivery_time'),
                    ]}
                    textList={[expectDeliveryDate, expectDeliveryPeriod]}
                />
            )}
        </VStack>
    );
};

export default OrderDetailContactView;
