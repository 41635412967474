import { Box, Checkbox, Circle, HStack, Icon, IconButton, Text, VStack, View } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import EvilIcons from 'react-native-vector-icons/dist/EvilIcons';
import { useParams } from 'react-router-dom';
import { useAppContext } from '../providers/AppProvider';
import {
    useDeleteCartItem,
    useIsCartMutating,
    useUpdateCartItem,
} from '../queries/cart';
import { flatDeep, formatDiscount, getMaxQuantityOfProduct } from '../utils/Utils';
import QuantityButton from './QuantityButton';
import { SkeletonImage } from './SkeletonImage';
import { SpecialCategories } from '../constrants/categories';
import { useNavigate } from 'react-router-dom';
import { usePathMaker } from '../utils/routeUtils';
import { FormatPrice } from './FormatPrice';
import _ from 'lodash';
import { useSelectStock, useSelectedStock, useUnselectStock } from '../providers/SelectStockProvider';

const ShoppingCartItemRow = ({
    product = {},
    isNarrow = false,
    editable = false,
    dropable = false,
}) => {
    const {
        productId,
        stockId,
        images = [],
        name,
        priceQuantity,
        color,
        answers: options,
        unitPrice,
        unitPoint,
        productTotalPrice,
        productTotalPoint,
        pointQuantity,
        usePoints,
        unitPointPrice,
        productTotalPointPrice,
        promotions,
        productSubtotalPrice,
    } = product;
    const { lang, shopId } = useAppContext();

    const selectedStock = useSelectedStock();
    const selectStocks = useSelectStock();
    const unselectStocks = useUnselectStock();

    const { mutate: updateCartItem } = useUpdateCartItem();

    const { mutate: deleteCartItem } = useDeleteCartItem();

    const isCartMutating = useIsCartMutating();

    const quantity = usePoints ? pointQuantity : priceQuantity;

    const updateQuantity = (quantity, usePoints = false) => {
        updateCartItem({
            lang,
            shopId,
            productId,
            stockId,
            priceQuantity: usePoints ? priceQuantity : quantity,
            pointQuantity: usePoints ? quantity : pointQuantity,
        });
    };

    const navigate = useNavigate();
    const link = usePathMaker.product(
        SpecialCategories.all.id,
        productId,
    );

    const goToProductDetail = () => {
        navigate(link);
    };

    const maxQuantity = getMaxQuantityOfProduct(product);

    const quantityButton = (
        <QuantityButton
            disabled={isCartMutating}
            quantity={quantity}
            onChange={newQuantity => {
                updateQuantity(newQuantity, usePoints);
            }}
            maxValue={maxQuantity}
        />
    );

    const { t } = useTranslation();

    const price = <FormatPrice
        price={unitPrice}
        memberPoint={unitPoint}
        memberPointPrice={unitPointPrice}
        usePoints={usePoints}
    />;

    const subTotal = 
        <FormatPrice
            price={productSubtotalPrice}
            discountPrice={productTotalPrice}
            memberPoint={productTotalPoint}
            memberPointPrice={productTotalPointPrice}
            usePoints={usePoints} />;

    const isChecked = _.includes(selectedStock, stockId);
    const isDisabled = useIsCartMutating();

    return (
        <VStack
            paddingBottom={'5'}
            space={'5'}
            borderBottomWidth={'1'}
            borderBottomColor={'warmGray.300'}
            marginBottom={'5'}>
            <HStack space={'5'}>
                <VStack flex={1}>
                    <HStack alignItems={'center'} space={'5'}>
                        {editable && (
                            <Checkbox
                                accessibilityLabel={stockId}
                                isChecked={isChecked}
                                isDisabled={isDisabled}
                                onChange={(value) => {
                                    if (value) {
                                        selectStocks([stockId]);
                                    } else {
                                        unselectStocks([stockId]);
                                    }
                                }}
                            />
                        )}
                        <View flex={'1'}>
                            <SkeletonImage
                                source={{ uri: flatDeep(images, Infinity)[0] }}
                                resizeMode={'contain'}
                            />
                        </View>
                    </HStack>
                </VStack>
                <VStack flex={2} space={'1.5'}>
                    <HStack
                        justifyContent={'space-between'}
                    >
                        <Text bold onPress={() => { goToProductDetail(); }}>{name}</Text>
                        {/** mobile - top right trash button */}
                        {(isNarrow && editable) && (
                            <IconButton
                                disabled={isCartMutating}
                                size={'6'}
                                icon={
                                    <Icon
                                        as={EvilIcons}
                                        name={'trash'}
                                        size={21}
                                        color={'lightText'}
                                    />
                                }
                                onPress={() => {
                                    updateQuantity(0, usePoints);
                                }}
                            />
                        )}
                    </HStack>
                    {color && (
                        <HStack alignItems={'center'}>
                            <Text>{`${t('Color')}: `}</Text>
                            {/#[a-zA-Z\d]{6}/.test(color) ? (
                                <Circle
                                    variant={'colorSelect'}
                                    backgroundColor={color}
                                />
                            ) : (
                                <Text>{color}</Text>
                            )}
                        </HStack>
                    )}
                    {options.map(({ question, answer }, index) => (
                        <Text key={index}>{`${question}: ${answer}`}</Text>
                    ))}
                    {!editable && (
                        <Text>{`${t('Quantity')}: ${quantity}`}</Text>
                    )}
                    {/** mobile and summary - promotion box and price quantity button */}
                    {(editable && isNarrow) && (
                        <>
                            {_.map(promotions, (promotion, index) => (
                                <HStack key={index} >
                                    <Box variant={'promotion'} maxWidth={'100%'}>
                                        <Text>{promotion.name}</Text>
                                        <Text><FormatPrice price={promotion.amtAdjust} /></Text>
                                    </Box>
                                </HStack>
                            ))}
                            <HStack
                            flex={1}
                            alignItems={'flex-end'}
                            justifyContent={'space-between'}>
                            <Text bold>{subTotal}</Text>
                            {quantityButton}
                        </HStack>
                        </>
                    )}
                    {/** summary - promotion box */}
                    {(!editable) && (
                        <>
                        {_.map(promotions, (promotion, index) => (
                            <HStack>
                                <HStack key={index} flex={4}>
                                    <Box variant={'promotion'} maxWidth={'100%'}>
                                        <Text>{promotion.name}</Text>
                                        <Text>
                                            <FormatPrice price={promotion.amtAdjust} />
                                        </Text>
                                    </Box>
                                </HStack>
                            </HStack>
                        ))}
                        </>
                    )}
                </VStack>
                {/** destkop - price quantity button */}
                {!isNarrow && editable && (
                    <>
                        <VStack flex={1}>
                            <Text>{price}</Text>
                        </VStack>
                        <VStack flex={1} alignItems={'flex-start'}>
                            {quantityButton}
                        </VStack>
                        <VStack flex={1}>
                            {_.map(promotions, (promotion, index) => (
                                <React.Fragment key={index}>
                                    <Text>{promotion.name}</Text>
                                    <Text>
                                        <FormatPrice price={promotion.amtAdjust} />
                                    </Text>
                                </React.Fragment>
                            ))}
                        </VStack>
                        <VStack flex={1}>
                            {subTotal}
                        </VStack>
                        <VStack flex={1}>
                            <IconButton
                                disabled={isCartMutating}
                                size={'6'}
                                icon={
                                    <Icon
                                        as={EvilIcons}
                                        name={'trash'}
                                        size={21}
                                        color={'lightText'}
                                    />
                                }
                                onPress={() => {
                                    updateQuantity(0, usePoints);
                                }}
                            />
                        </VStack>
                    </>
                )}
                {/** summary - top right price */}
                {(!editable) && (
                    <VStack
                        flex={2}
                        justifyContent={'space-between'}
                    >
                        <VStack flex={1} alignItems={'flex-end'}>
                            {subTotal}
                        </VStack>
                        {/** mobile - bottom right drop button */}
                        {(isNarrow && dropable) && (
                            <IconButton
                                alignSelf={'flex-end'}
                                disabled={isCartMutating}
                                size={'6'}
                                icon={
                                    <Icon
                                        as={EvilIcons}
                                        name={'trash'}
                                        size={21}
                                        color={'lightText'}
                                    />
                                }
                                onPress={() => {
                                    updateQuantity(0, usePoints);
                                }}
                            />
                        )}
                    </VStack>

                )}
            </HStack>
        </VStack>
    );
};

export default ShoppingCartItemRow;
