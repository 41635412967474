import { extendTheme } from 'native-base';

export const mainTheme = ({ colors = [], colorTone }) => {
    return extendTheme({
        colors: {
            lightText: colorTone,
            darkText: '#262626',
            primary: {
                50: colors[0],
                100: colors[1],
                200: colors[2],
                300: colors[3],
                400: colors[4],
                500: colors[5],
                600: colors[6],
                700: colors[7],
                800: colors[8],
                900: colors[9],
            },
        },
        components: {
            Menu: {
                baseStyle: {
                    borderRadius: 'xl',
                },
            },
            MenuItem: {
                baseStyle: {
                    _light: {
                        _text: {
                            color: 'lightText',
                            fontSize: 'sm',
                        },
                    },
                },
            },
            View: {
                variants: {
                    container: {
                        margin: '5',
                    },
                },
            },
            VStack: {
                variants: {
                    container: {
                        margin: '5',
                    },
                },
            },
            FlatList: {
                variants: {
                    container: {
                        margin: '5',
                    },
                },
            },
            Image: {
                variants: {
                    icon: {
                        borderRadius: 'none',
                        size: 'full',
                        resizeMode: 'contain',
                    },
                    logo: {
                        borderRadius: 'none',
                        height: '16',
                        width: '100px',
                        resizeMode: 'contain',
                    },
                    itemThumbnail: {
                        width: 'full',
                        height: 'full',
                        borderRadius: 10,
                    },
                },
            },
            Text: {
                defaultProps: {
                    variant: 'main',
                },
                baseStyle: {
                    marginY: '1',
                    fontSize: 'md',
                },
                variants: {
                    main: {
                        _light: {
                            color: 'lightText',
                        },
                    },
                    sub: {
                        _light: {
                            color: 'darkText',
                        },
                    },
                },
            },
            Circle: {
                variants: {
                    colorSelect: {
                        borderWidth: '1px',
                        borderColor: 'lightText',
                        size: '5',
                    },
                    colorUnselect: {
                        borderWidth: '0px',
                        borderColor: 'lightText',
                        size: '5',
                    },
                },
            },
            Button: {
                baseStyle: {},
                defaultProps: {
                    variant: 'main',
                },
                variants: {
                    main: {
                        borderWidth: '1px',
                        borderColor: 'lightText',
                        rounded: 'xl',
                        width: '1/2',
                        maxWidth: '56',
                        paddingTop: '2',
                        paddingBottom: '2',
                        _focus: {
                            bg: 'lightText',
                            _text: {
                                color: 'white',
                            },
                        },
                        _hover: {
                            bg: 'lightText',
                            _text: {
                                color: 'white',
                            },
                        },
                    },
                    fullRoundedFill: {
                        borderRadius: 'full',
                        bg: 'darkText',
                        width: 'full',
                        height: '10',
                        _text: {
                            color: 'white',
                            marginRight: '3',
                        },
                        _icon: {
                            color: 'white',
                            marginLeft: '3',
                        },
                    },
                    roundedFill: {
                        borderRadius: 'full',
                        bg: 'darkText',
                        height: '10',
                        _text: {
                            color: 'white',
                            marginRight: '3',
                        },
                        _icon: {
                            color: 'white',
                            marginLeft: '3',
                        },
                    },
                    fullRoundedLight: {
                        borderRadius: 'full',
                        bg: 'white',
                        width: 'full',
                        height: '10',
                        borderWidth: '1px',
                        borderColor: 'lightText',
                        _text: {
                            color: 'lightText',
                        },
                        _icon: {
                            color: 'lightText',
                        },
                        paddingLeft: '5',
                        paddingRight: '5',
                    },
                    roundedLight: {
                        borderRadius: 'full',
                        bg: 'white',
                        height: '10',
                        borderWidth: '1px',
                        borderColor: 'lightText',
                        _text: {
                            color: 'lightText',
                        },
                        _icon: {
                            color: 'lightText',
                        },
                        paddingLeft: '5',
                        paddingRight: '5',
                    },
                    select: {
                        borderRadius: 'full',
                        bg: 'white',
                        height: '8',
                        borderWidth: '1px',
                        borderColor: 'lightText',
                        _text: {
                            color: 'lightText',
                            marginX: '2',
                        },
                        _icon: {
                            color: 'lightText',
                        },
                    },
                    selected: {
                        borderRadius: 'full',
                        bg: 'lightText',
                        height: '8',
                        borderWidth: '1px',
                        borderColor: 'lightText',
                        _text: {
                            color: 'white',
                            marginX: '2',
                        },
                        _icon: {
                            color: 'white',
                        },
                    },
                },
            },
            Pressable: {
                variants: {
                    select: {
                        borderRadius: 'full',
                        bg: 'white',
                        height: '8',
                        borderWidth: '1px',
                        borderColor: 'lightText',
                        _text: {
                            color: 'lightText',
                            marginX: '2',
                        },
                        _icon: {
                            color: 'lightText',
                        },
                    },
                    selected: {
                        borderRadius: 'full',
                        bg: 'lightText',
                        height: '8',
                        borderWidth: '1px',
                        borderColor: 'lightText',
                        _text: {
                            color: 'white',
                            marginX: '2',
                        },
                        _icon: {
                            color: 'white',
                        },
                    },
                },
            },
            Box: {
                variants: {
                    card: {
                        backgroundColor: '#F7F7F7',
                        borderColor: 'lightText',
                        borderWidth: 1,
                        borderRadius: 'xl',
                        padding: '5',
                    },
                    promotion: {
                        backgroundColor: '#F7F7F7',
                        borderColor: 'lightText',
                        borderWidth: 1,
                        borderRadius: 'xl',
                        padding: '2',
                    },
                },
            },
        },
    });
};

export const checkoutTheme = ({ colors = [] }) => extendTheme({
    colors: {
        lightText: '#262626',
        darkText: '#262626',
        subBackgroundColor: '#FAFAFA',
        primary: {
            50: colors[0],
            100: colors[1],
            200: colors[2],
            300: colors[3],
            400: colors[4],
            500: colors[5],
            600: colors[6],
            700: colors[7],
            800: colors[8],
            900: colors[9],
        },
    },
    components: {
        Text: {
            baseStyle: {
                _light: {
                    color: 'lightText',
                },
            },
        },
        Button: {
            variants: {
                fullRoundedFill: {
                    borderRadius: 'full',
                    bg: 'lightText',
                    width: 'full',
                    height: '12',
                    _text: {
                        color: 'white',
                        marginX: '5',
                    },
                    _icon: {
                        color: 'white',
                        marginLeft: '3',
                    },
                },
                roundedFill: {
                    borderRadius: 'full',
                    bg: 'lightText',
                    height: '10',
                    _text: {
                        color: 'white',
                        marginX: '5',
                    },
                    _icon: {
                        color: 'white',
                        marginLeft: '3',
                    },
                },
                roundedFillOutline: {
                    borderRadius: 'full',
                    borderColor: 'lightText',
                    borderWidth: 2,
                    backgroundColor: 'white',
                    height: '10',
                    _text: {
                        color: 'lightText',
                        marginX: '5',
                    },
                    _icon: {
                        color: 'lightText',
                        marginLeft: '3',
                    },
                }
            },
        },
        Circle: {
            variants: {
                colorSelect: {
                    borderWidth: '1px',
                    borderColor: 'lightText',
                    size: '5',
                },
            },
        },
        Input: {
            defaultProps: {
                variant: 'base',
            },
            variants: {
                base: {
                    _light: {
                        borderWidth: '1',
                        borderColor: 'muted.300',
                        _hover: {
                            borderColor: 'muted.400',
                        },
                        _stack: {
                            _focus: {
                                style: {
                                    outlineWidth: '0',
                                },
                            },
                        },
                        _focus: {
                            bg: 'white',
                            borderColor: 'muted.400',
                            _hover: {
                                borderColor: 'muted.400',
                            },
                            _stack: {
                                style: {
                                    outlineWidth: '0',
                                },
                            },
                        },
                    },
                },
            },
        },
        Box: {
            variants: {
                promotion: {
                    backgroundColor: '#F7F7F7',
                    borderColor: 'lightText',
                    borderWidth: 1,
                    borderRadius: 'xl',
                    padding: '2',
                },
            },
        },
    },
});
