import React, { useMemo } from 'react';
import chroma from 'chroma-js';
import { useShopInfo } from './queries/shop';
import { NativeBaseProvider } from 'native-base';
import { checkoutTheme, mainTheme } from './theme';
import { useLocation } from 'react-router-dom';
import { RNCenterLoading } from './components/RNCenterLoading';

const LIGHTNESS_MAP = [
  0.95, 0.85, 0.75, 0.65, 0.55, 0.45, 0.35, 0.25, 0.15, 0.05,
];

const genColors = (color = '#000000') => {
  return LIGHTNESS_MAP.map(l => chroma(color).saturate(l).hex());
};

const AppThemeProvider = ({
  children,
}) => {
  const {
    data: shopInfo,
    isLoading,
  } = useShopInfo();

  const { colorTone } = shopInfo;

  const { pathname } = useLocation();
  const isCheckoutPage = pathname.indexOf('checkout') > 0 || /order\/.*\/success/gm.test(pathname);

  const theme = useMemo(() => {
    if (isCheckoutPage) {
      return checkoutTheme({ 
        colors: genColors(),
      });
    } 

    const colors = genColors(colorTone);

    return mainTheme({
      colorTone,
      colors,
    });
  }, [colorTone, isCheckoutPage]);
  
  if (isLoading) {
    return <RNCenterLoading />;
  }

  return (
    <NativeBaseProvider theme={theme}>
      {children}
    </NativeBaseProvider>
  );
};

export default AppThemeProvider;