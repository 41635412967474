import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { queries } from '../queries';
import { useAppContext } from '../providers/AppProvider';
import useCurrentPage from '../utils/useCurrentPage';
import { useDispatch } from 'react-redux';
import { dropShoppingCartAction } from '../store/actions/ShoppingCartActions';

const ShoppingCartQueriesResetComp = ({
  children,
  isCheckout = false,
}) => {
  const queryClient = useQueryClient();
  const [resetDone, setResetDone] = useState(false);
  const { shopId, lang } = useAppContext();
  const currentPage = useCurrentPage();
  const dispatch = useDispatch();

  useEffect(() => {
    if (resetDone) return;

    if (!isCheckout) {
      dispatch(dropShoppingCartAction());
      queryClient.invalidateQueries(queries.cartDetail({shopId, lang}).queryKey);
    }
    
    if (isCheckout) {
      queryClient.resetQueries(queries.cartDetail({shopId, lang}).queryKey);
    }
    
    setResetDone(true);
  }, [shopId, lang, resetDone, isCheckout]);

  useEffect(() => {
    if (isCheckout) {
      queryClient.invalidateQueries(queries.cartDetail({shopId, lang}).queryKey);
    }
  }, [shopId, lang, currentPage, isCheckout]);

  if (!resetDone) {
    return null;
  }

  return children;
};

export default ShoppingCartQueriesResetComp;