import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppContext } from '../providers/AppProvider';
import { pageRoutes } from '../Routes';
import { matchPath } from './routeUtils';

const useCurrentPage = () => {
    const { pathname } = useLocation();
    const { isUsingShopDomain } = useAppContext();

    return useMemo(() => {
        let found = {};

        Object.entries(pageRoutes).forEach(([key, route]) => {
            if (
                route.path !== pageRoutes.page.path &&
                matchPath(
                    isUsingShopDomain
                        ? route.path.replace('/shop/:shopShortName', '')
                        : route.path,
                    pathname,
                )
            ) {
                found = route;
            }
        });

        return found;
    }, [pathname, isUsingShopDomain]);
};

export default useCurrentPage;
