import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { Animated, Platform, StyleSheet, View } from 'react-native';
import FieldBarcode from './FieldBarcode';
import FieldBubble from './FieldBubble';
import FieldCalendar from './FieldCalendar';
import FieldCheckbox from './FieldCheckbox';
import FieldDate from './FieldDate';
import FieldGroupped from './FieldGrouped';
import FieldPhoto from './FieldPhoto';
import FieldSelect from './FieldSelect';
import FieldSelectModal from './FieldSelectModal';
import FieldText from './FieldText';
import FieldSelectModalEditable from './FieldSelectModalEditable';
import { allStyles } from './FormStyles';
import { translate, useTranslateFunc } from './FormUtils';

export const FieldType = {
    phone: 'PHONE',
    email: 'EMAIL',
    text: 'TEXT',
    barcode: 'BARCODE',
    photo: 'PHOTO',
    date: 'DATE',
    select: 'SELECT',
    calendar: 'CALENDAR',
    bubble: 'BUBBLE',
    selectModal: 'SELECT_MODAL',
    grouped: 'GROUPED',
    checkbox: 'CHECKBOX',
    countryCode: 'COUNTRYCODE',
};

export function renderField(
    props,
    index = 0,
    getValueByName,
    onChange,
    getItemsByName,
    getErrorByName,
    onBlur,
) {
    const zIndex = 100 - index;
    if (Array.isArray(props)) {
        return (
            <View style={[styles.fieldRow, { zIndex }]}>
                {props.map((childProps, index) => {
                    const marginRight = index != props.length - 1 ? 10 : 0;
                    return (
                        <View key={index} style={{ flex: 1, marginRight }}>
                            {renderField(
                                childProps,
                                zIndex,
                                getValueByName,
                                onChange,
                                getItemsByName,
                                getErrorByName,
                                onBlur,
                            )}
                        </View>
                    );
                })}
            </View>
        );
    } else {
        const value = getValueByName(props.name);
        const error = getErrorByName(props.name);

        if (
            typeof props.hideIf === 'object' &&
            (getValueByName(props.hideIf.name) === props.hideIf.value ||
                (getValueByName(props.hideIf.name) === undefined &&
                    props.hideIf.value === false))
        ) {
        } else if (typeof props.hideIf === 'function' && props.hideIf()) {
        } else if (props.fieldType === FieldType.text) {
            return (
                <FieldText
                    key={index}
                    {...props}
                    containerStyle={{ zIndex }}
                    value={value}
                    onChange={onChange}
                    error={error}
                    onBlur={onBlur}
                />
            );
        } else if (props.fieldType === FieldType.countryCode) {
            return (
                <FieldSelectModalEditable
                    key={index}
                    {...props}
                    containerStyle={{ zIndex }}
                    value={value}
                    onChange={onChange}
                    getItemsByName={getItemsByName}
                    error={error}
                    onBlur={onBlur}
                    keyboardType='phone-pad'
                />
            );
        } else if (props.fieldType === FieldType.phone) {
            return (
                <FieldText
                    key={index}
                    {...props}
                    containerStyle={{ zIndex }}
                    value={value}
                    onChange={onChange}
                    error={error}
                    onBlur={onBlur}
                    keyboardType='phone-pad'
                />
            );
        } else if (props.fieldType === FieldType.email) {
            return (
                <FieldText
                    key={index}
                    {...props}
                    containerStyle={{ zIndex }}
                    value={value}
                    onChange={onChange}
                    error={error}
                    onBlur={onBlur}
                    keyboardType='email-address'
                />
            );
        } else if (props.fieldType === FieldType.select) {
            return (
                <FieldSelect
                    key={index}
                    {...props}
                    containerStyle={{ zIndex }}
                    value={value}
                    onChange={onChange}
                    getItemsByName={getItemsByName}
                    error={error}
                />
            );
        } else if (props.fieldType === FieldType.photo) {
            return (
                <FieldPhoto
                    key={index}
                    {...props}
                    containerStyle={{ zIndex }}
                    value={value}
                    onChange={onChange}
                    error={error}
                />
            );
        } else if (props.fieldType === FieldType.barcode) {
            return (
                <FieldBarcode
                    key={index}
                    {...props}
                    containerStyle={{ zIndex }}
                    value={value}
                    onChange={onChange}
                    error={error}
                    onBlur={onBlur}
                />
            );
        } else if (props.fieldType === FieldType.date) {
            return (
                <FieldDate
                    key={index}
                    {...props}
                    containerStyle={{ zIndex }}
                    value={value}
                    onChange={onChange}
                    error={error}
                />
            );
        } else if (props.fieldType === FieldType.bubble) {
            return (
                <FieldBubble
                    key={index}
                    {...props}
                    containerStyle={{ zIndex }}
                    value={value}
                    onChange={onChange}
                    getItemsByName={getItemsByName}
                    error={error}
                />
            );
        } else if (props.fieldType === FieldType.calendar) {
            return (
                <FieldCalendar
                    key={index}
                    {...props}
                    containerStyle={{ zIndex }}
                    value={value}
                    onChange={onChange}
                    error={error}
                />
            );
        } else if (props.fieldType === FieldType.selectModal) {
            return (
                <FieldSelectModal
                    key={index}
                    {...props}
                    containerStyle={{ zIndex }}
                    value={value}
                    onChange={onChange}
                    getItemsByName={getItemsByName}
                    error={error}
                />
            );
        } else if (props.fieldType === FieldType.grouped) {
            return (
                <FieldGroupped
                    key={index}
                    {...props}
                    containerStyle={{ zIndex }}
                    value={value}
                    onChange={onChange}
                    getItemsByName={getItemsByName}
                    error={error}
                    getValueByName={getValueByName}
                    getErrorByName={getErrorByName}
                    onBlur={onBlur}
                />
            );
        } else if (props.fieldType === FieldType.checkbox) {
            return (
                <FieldCheckbox
                    key={index}
                    {...props}
                    containerStyle={{ zIndex }}
                    value={value}
                    onChange={onChange}
                    getItemsByName={getItemsByName}
                    error={error}
                    getValueByName={getValueByName}
                    getErrorByName={getErrorByName}
                    onBlur={onBlur}
                />
            );
        } else {
            return <View key={index} />;
        }
    }
}

export const SimpleForm = forwardRef((props, ref) => {
    const {
        fields = [],
        values = {},
        onChange = () => {},
        getItemsByName = () => {},
        onBlur = () => {},
    } = props;

    const [errors, setErrors] = useState({});

    const getValueByName = name => {
        return values[name];
    };

    const getErrorByName = name => {
        return errors[name];
    };

    const translateFunc = useTranslateFunc();

    useImperativeHandle(ref, () => ({
        isValidForm: () => {
            let errors = {};
            let isValid = true;

            const checkField = (field, input, index) => {
                const value = input ?? getValueByName(field.name);
                if (
                    typeof field.hideIf === 'object' &&
                    (getValueByName(field.hideIf.name) === field.hideIf.value ||
                        (getValueByName(field.hideIf.name) === undefined &&
                            field.hideIf.value === false))
                ) {
                } else if (
                    typeof field.hideIf === 'function' &&
                    props.hideIf()
                ) {
                } else if (Array.isArray(value)) {
                    value.forEach((childValue, index) => {
                        checkField(field, childValue, index);
                    });
                } else if (
                    field.required &&
                    typeof value === 'object' &&
                    Object.keys(value).length === 0
                ) {
                    errors[field.name] =
                        field.customWarming ??
                        `${translateFunc([
                            'FORM',
                            'INVALID_INPUT',
                        ])}${translateFunc(
                            field.title || field.placeholder,
                        ).toLowerCase()}`;
                    isValid = false;
                } else if (field.required && !value) {
                    errors[field.name] =
                        field.customWarming ??
                        `${translateFunc([
                            'FORM',
                            'INVALID_INPUT',
                        ])}${translateFunc(
                            field.title || field.placeholder,
                        ).toLowerCase()}`;
                    isValid = false;
                } else if (
                    value &&
                    Array.isArray(field.rules) &&
                    (!field.shouldMatch || field.shouldMatch[index])
                ) {
                    for (let i = 0; i < field.rules.length; i++) {
                        const rule = field.rules[i];
                        if (rule.regex) {
                            const regex = new RegExp(rule.regex);
                            if (!regex.test(value)) {
                                errors[field.name] =
                                    rule.message ??
                                    `${translateFunc([
                                        'FORM',
                                        'INVALID_INPUT',
                                    ])}${translateFunc(
                                        field.title,
                                    ).toLowerCase()}`;
                                isValid = false;
                                break;
                            }
                        }
                    }
                }

                if (Array.isArray(field.fields)) {
                    field.fields.forEach(childField => {
                        if (Array.isArray(childField)) {
                            childField.forEach(itemField => {
                                checkField(itemField);
                            });
                        } else {
                            checkField(childField);
                        }
                    });
                }
            };

            fields.forEach(field => {
                if (Array.isArray(field)) {
                    field.forEach(childField => {
                        checkField(childField);
                    });
                } else {
                    checkField(field);
                }
            });

            setErrors(errors);
            return isValid;
        },
    }));

    const fadeAnim = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 300,
            useNativeDriver: false,
        }).start();
    }, []);

    return (
        <Animated.View
            style={{ flex: 1, opacity: fadeAnim }}
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
            {fields.map((field, index) => (
                <React.Fragment key={index}>
                    {renderField(
                        field,
                        index,
                        getValueByName,
                        onChange,
                        getItemsByName,
                        getErrorByName,
                        onBlur,
                    )}
                </React.Fragment>
            ))}
        </Animated.View>
    );
});

const styles = StyleSheet.create(allStyles);
