import React, { createContext, useContext, useMemo } from 'react';

const AppContext = createContext({});

export const AppContextProvider = ({
    shopShortName,
    shopId,
    shopDomain,
    lang,
    isUsingShopDomain,
    children,
}) => {
    const memorizedValue = useMemo(() => ({
        shopShortName,
        shopId,
        shopDomain,
        lang,
        isUsingShopDomain,
    }), [
        shopShortName,
        shopId,
        shopDomain,
        lang,
        isUsingShopDomain
    ]);

    return (
        <AppContext.Provider
            value={memorizedValue}
        >
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => {
    return useContext(AppContext);
};

export const useAppContextCallback = (callback) => {
    const params = useAppContext();
    return callback(params);
}