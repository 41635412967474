import { ScrollView } from 'native-base';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useOutlet } from 'react-router-dom';
import { useIsFirstRender } from 'usehooks-ts';
import NavigationHeader from './components/NavigationHeader';

let scrollOffsetHistory = {};

export default () => {
    const { scrollY, updatedAt } = useSelector(state => state.ui);

    const [headerHeight, setHeaderHeight] = useState(0);
    let scrollRestored = true;

    const outlet = useOutlet({
        headerHeight,
    });

    const scrollViewRef = useRef(null);

    const location = useLocation();
    const { pathname, key } = location;

    const isFirstRender = useIsFirstRender();

    useEffect(() => {
        if (!scrollOffsetHistory[key]) {
            scrollViewRef.current?.scrollTo({ y: 0, animated: false });
        } else {
            scrollRestored = false;
        }
    }, [key]);

    useEffect(() => {
        if (!isFirstRender && typeof scrollY === 'number' && updatedAt) {
            scrollViewRef.current?.scrollTo({ y: scrollY, animated: true });
        }
    }, [scrollY, updatedAt]);

    return (
        <ScrollView
            ref={scrollViewRef}
            overflowY={'scroll'}
            onScroll={e => {
                scrollOffsetHistory[key] = e.nativeEvent.contentOffset.y;
            }}
            onContentSizeChange={(w, h) => {
                if (
                    scrollOffsetHistory[key] &&
                    h > scrollOffsetHistory[key] &&
                    !scrollRestored
                ) {
                    scrollRestored = true;
                    scrollViewRef.current.scrollTo({
                        y: scrollOffsetHistory[key],
                        animated: false,
                    });
                }
            }}
            scrollEventThrottle={16}
            stickyHeaderIndices={[0]}
        >
            <NavigationHeader onHeightChanged={setHeaderHeight} />
            {outlet}
        </ScrollView>
    );
};
