import { HStack, Text, VStack } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormatPrice } from './FormatPrice';

const TitleContentRow = ({ title, content, bold }) => {
    return (
        <HStack>
            <Text bold={bold}>{title}</Text>
            <Text flex={1} textAlign={'right'} bold={bold}>
                {content}
            </Text>
        </HStack>
    );
};

export default ({
    cartOrOrder = {},
    hideRemark = false
}) => {
    const {
        subtotal,
        shippingCharge,
        finalShippingCharge,
        total,
        subtotalPoint,
        totalPoint = 0,
        remark,
        promotions,
        shippingChargePromotions,
        promotionCode,
    } = cartOrOrder;

    const { t } = useTranslation();

    return (
        <VStack space={'5'}>
            {remark && !hideRemark && (
                <TitleContentRow title={t('Remark') + ':'} content={remark} />
            )}
            {promotionCode && (
                <TitleContentRow
                    title={t('PROMOTION_CODE') + ':'}
                    content={promotionCode}
                />
            )}
            <TitleContentRow
                title={t('Subtotal') + ':'}
                content={<FormatPrice price={subtotal}/>}
            />
            {Array.isArray(promotions) &&
                promotions.map((promotion, index) => (
                    <TitleContentRow
                        key={index}
                        title={`${promotion.name}:`}
                        content={<FormatPrice price={promotion.amtAdjust}/>}
                    />
                ))}
            {shippingCharge > 0 && (
                <TitleContentRow
                    title={t('Delivery fee') + ':'}
                    content={<FormatPrice price={shippingCharge}/>}
                />
            )}
            {Array.isArray(shippingChargePromotions) &&
                shippingChargePromotions.map((promotion, index) => (
                    <TitleContentRow
                        key={index}
                        title={`${promotion.name}:`}
                        content={<FormatPrice price={promotion.amtAdjust}/>}
                    />
                ))}
            <TitleContentRow
                title={t('Total') + ':'}
                content={<FormatPrice price={total}/>}
                bold
            />
            {totalPoint > 0 && (
                <TitleContentRow
                    title={t('Used points') + ':'}
                    content={`${totalPoint}` + t('POINTS')}
                    bold
                />
            )}
        </VStack>
    );
};
