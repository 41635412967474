import { VStack } from 'native-base';
import React, { useEffect } from 'react';
import useMeasure from 'react-use-measure';
import CheckoutMemberInfo from './CheckoutMemberInfo';
import Separator from './Separator';
import ShoppingCartCalculationView from './ShoppingCartCalculationView';
import ShoppingCartItemTable from './ShoppingCartItemTable';
import PromotionCodeInput from './PromotionCodeInput';

export const CheckoutItemListType = {
    normal: 'normal',
    record: 'record',
};

const CheckoutItemList = ({
    onLayout = () => {},
    style,
    hideMemberInfo = false,
    hideBorder = false,
    showPromotionInput = false,
    cartOrOrder = {},
}) => {
    const [ref, { height }] = useMeasure();

    useEffect(() => {
        onLayout(height);
    }, [height]);

    return (
        <VStack
            ref={ref}
            borderRadius={'xl'}
            borderColor={'#E5E5E5'}
            borderWidth={hideBorder ? 0 : '1'}
            backgroundColor={hideBorder ? 'transparent' : '#FAFAFA'}
            overflow={'hidden'}
            style={style}>
            <VStack margin={'5'} space={'5'}>
                <ShoppingCartItemTable
                    data={cartOrOrder.inventories}
                    hideTitle={hideMemberInfo}
                />
                {showPromotionInput && (
                    <PromotionCodeInput />
                )}
                <ShoppingCartCalculationView
                    cartOrOrder={cartOrOrder}
                />
                {!hideMemberInfo && (
                    <>
                        <Separator />
                        <CheckoutMemberInfo />
                    </>
                )}
            </VStack>
        </VStack>
    );
};

export default CheckoutItemList;
